import { ImageSourcePropType, StyleSheet, View } from "react-native";
import { Image } from "expo-image";
import React, { useEffect, useMemo, useState } from "react";
import GalleryArrow, { GalleryArrowDirection } from "./GalleryArrow";
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import {
  Directions,
  Gesture,
  GestureDetector,
} from "react-native-gesture-handler";
interface GalleryProps {
  images: ImageSourcePropType[];
  width: number;
}

const Gallery = ({ images, width }: GalleryProps) => {
  const [imageIndexes, setImageIndexes] = useState<[number, number]>([0, 0]);
  const [visibleImage, setVisibleImage] = useState<0 | 1>(0);
  const galleryLength = useMemo(() => images.length, [images]);
  const config = {
    duration: 50,
    easing: Easing.linear,
  };
  const opacitys = { 0: useSharedValue(1), 1: useSharedValue(0) };
  const arrowButtonFunction = (direction: GalleryArrowDirection) => {
    const newImageIndexes: [number, number] = [0, 0];
    if (visibleImage === 0) {
      newImageIndexes[0] = imageIndexes[0];
      newImageIndexes[1] =
        (((imageIndexes[0] + direction) % galleryLength) + galleryLength) %
        galleryLength;
    } else {
      newImageIndexes[0] =
        (((imageIndexes[1] + direction) % galleryLength) + galleryLength) %
        galleryLength;
      newImageIndexes[1] = imageIndexes[1];
    }

    setImageIndexes(newImageIndexes);
    setVisibleImage(visibleImage === 0 ? 1 : 0);
  };
  const handleSwipe = (direction: "left" | "right") => {
    arrowButtonFunction({ left: -1, right: 1 }[direction]);
  };
  useEffect(() => {
    console.log({
      visibleImage,
      imageIndexe0: imageIndexes[0],
      imageIndexe1: imageIndexes[1],
    });
  }, [visibleImage, imageIndexes]);
  useEffect(() => {
    opacitys[visibleImage].value = 1;
    opacitys[visibleImage === 0 ? 1 : 0].value = 0;
  }, [config.duration, opacitys, visibleImage]);

  const style = {
    0: useAnimatedStyle(() => {
      return {
        opacity: withTiming(opacitys[0].value, config),
      };
    }),
    1: useAnimatedStyle(() => {
      return {
        opacity: withTiming(opacitys[1].value, config),
      };
    }),
  };

  const flingNext = Gesture.Fling()
    .direction(Directions.RIGHT)
    .onStart(() => {
      runOnJS(arrowButtonFunction)(GalleryArrowDirection.Next);
    });
  const flingPrevious = Gesture.Fling()
    .direction(Directions.LEFT)
    .onStart(() => {
      runOnJS(arrowButtonFunction)(GalleryArrowDirection.Previous);
    });
  const composedGesture = Gesture.Race(flingNext, flingPrevious);

  return (
    <View style={styles.gallery}>
      <GalleryArrow
        size={width > 2500 ? 106 : 64}
        direction={GalleryArrowDirection.Previous}
        changeIndex={arrowButtonFunction}
        disabled={imageIndexes[visibleImage] <= 0}
      />
      <GestureDetector gesture={composedGesture} style={{ flex: 1 }}>
      <View style={styles.wrapper}>
        <Animated.View
          key={`wrapper0${imageIndexes[0]}`}
          style={StyleSheet.compose(styles.animatedWrapper, style[0])}
        >
          <Image
            contentFit="contain"
            key={`image0${imageIndexes[0]}`}
            style={styles.image}
            source={images[imageIndexes[0]]}
          />
        </Animated.View>
        <Animated.View
          key={`wrapper1${imageIndexes[1]}`}
          style={StyleSheet.compose(styles.animatedWrapper, style[1])}
          >
          <Image
            contentFit="contain"
            key={`image2${imageIndexes[1]}`}
            style={styles.image}
            source={images[imageIndexes[1]]}
            />
        </Animated.View>
      </View>
      </GestureDetector>
      <GalleryArrow
        size={width > 2500 ? 106 : 64}
        direction={GalleryArrowDirection.Next}
        changeIndex={arrowButtonFunction}
        disabled={imageIndexes[visibleImage] >= galleryLength - 1}
      />
    </View>
  );
};

export default Gallery;

const styles = StyleSheet.create({
  gallery: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  wrapper: {
    flex: 1,
    display: "flex",
    paddingHorizontal: 10,
  },
  animatedWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
  },
  image: {
    flex: 1,
  },
});
