import { BlobID, ctx } from './BlobContext';

export const animateToScene = function(animSequence: any) {  
  // generate animations
  for(let i=0; i < animSequence.length; i++) {
    let blobsTarget: any = [];

    Object.values(BlobID).forEach(id => {
      let target = animSequence[i].target[id];
      if(!target) 
        return
  
      let anim: any = {
        id: id,
        targetX: target[0],
        targetY: target[1],
        targetSize: target[2],
      }

      if(target.length > 3) {
        anim.ctrlDir1 = [target[3], target[4]];
        anim.ctrlDir2 = [target[5], target[6]];
      }
      blobsTarget.push(anim);
    })
  
    ctx.setAnimation({
      deltaTime: 0,
      timefunction: animSequence[i].timefunction,
      time: animSequence[i].time,
      blobs: blobsTarget
    })
  }
  
}

