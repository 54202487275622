import { Image } from "expo-image";
import React, { useEffect, useMemo, useState } from "react";
import { ImageBackground, Pressable, StyleSheet, View } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from "react-native-reanimated";
import Constants from "./Constants";
import { GestureDetector, Gesture } from "react-native-gesture-handler";
interface WorkForUsQrCodeProps {
  top: number;
  left: number;
  width: number;
  menuWidth: number;
  visible: boolean;
  visibleTimingDuration: number;
  visibleDelay: number;
  showScanMeToggle: boolean;
}
const WorkForUsQrCode = ({
  top,
  left,
  width,
  menuWidth,
  visible,
  visibleTimingDuration,
  visibleDelay,
  showScanMeToggle
}: WorkForUsQrCodeProps) => {
  const calculatedTop = useMemo(
    () => top + 2 * Constants.fontSize * menuWidth * 0.8,
    [menuWidth, top]
  );
  const calculatedWidth = useMemo(() => width * 0.45, [width]);
  const calculatedHeight = useMemo(
    () => (calculatedWidth / 3) * 4,
    [calculatedWidth]
  );
  const style = StyleSheet.create({
    view: {
      left,
      top: calculatedTop,
      width: calculatedWidth,
      height: calculatedHeight,
      overflow: "hidden",
      position: "relative",
    },
  });
  const topOffset = useSharedValue(-calculatedHeight);
  const opacity = useSharedValue(0);
  const menuOpacity = useSharedValue(0);

  const config = {
    duration: 1000,
  };
  const slideInAnimationStyle = useAnimatedStyle(() => {
    return {
      flex: 1,
      transform: [{ translateY: withTiming(topOffset.value, config) }],
      opacity: withTiming(opacity.value, config),
    };
  });
  useEffect(() => {
    if (visible) {
      toggleScanMe();
    }
  }, [showScanMeToggle])
  useEffect(() => {
    menuOpacity.value = visible ? 1 : 0;
    topOffset.value = -calculatedHeight;
    opacity.value = 0;
  }, [visible]);

  const toggleScanMe = () => {
    topOffset.value = topOffset.value === 0 ? -calculatedHeight : 0;
    opacity.value = opacity.value === 0 ? 1 : 0;
  }
  const visibilityStyle = useAnimatedStyle(() => {
    return {
      opacity: withDelay(
        visibleDelay,
        withTiming(menuOpacity.value, { duration: visibleTimingDuration })
      ),
    };
  });
  return (
    <Pressable
      onPress={toggleScanMe}
    >
      <Animated.View style={StyleSheet.compose(style.view, visibilityStyle)}>
        <Animated.View
          style={StyleSheet.compose(styles.image, slideInAnimationStyle)}
        >
          <Image
            style={styles.image}
            contentFit="contain"
            source={require("../assets/images/work_for_us_scan_me.png")}
          />
        </Animated.View>
        <Image
          style={StyleSheet.compose(style.view, styles.image)}
          source={require("../assets/images/work_for_us_only_qr.png")}
        />
      </Animated.View>
    </Pressable>
  );
};

export default WorkForUsQrCode;

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
  },
});
