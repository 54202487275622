import { View, StyleSheet, ImageBackground } from "react-native";
import React, { useEffect, useState } from "react";
import CloseButton from "./CloseButton";
import { MenuState } from "./Constants";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from "react-native-reanimated";
enum ModalState {
  hidden,
  visible,
}
interface ModalComponentProps {
  width: number;
  height: number;
  hasBorder: boolean;
  children: any;
  setMenuState?: React.Dispatch<React.SetStateAction<MenuState>>;
}
const ModalComponent = ({
  width,
  height,
  hasBorder,
  children,
  setMenuState = () => { },
}: ModalComponentProps) => {
  const [animationState, setAnimationState] = useState<ModalState>(
    ModalState.visible
  );
  const backdropOpacity = useSharedValue(0);
  const modalOpacity = useSharedValue(0);
  const animationDelay = useSharedValue(0);
  const config = {
    duration: 500,
    easing: Easing.linear,
  };
  const backdropStyle = useAnimatedStyle(() => {
    return {
      opacity: withDelay(
        animationDelay.value, // restore gl time, auf diese Weise können wir während dem Modal das rendern des GL Contexts disablen
        withTiming(backdropOpacity.value, config)
      ),
    };
  });
  const modalStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(modalOpacity.value, config),
    };
  });
  useEffect(() => {
    switch (animationState) {
      case ModalState.hidden:
        animationDelay.value = 800;
        backdropOpacity.value = 0;
        modalOpacity.value = 0;
        break;
      case ModalState.visible:
      default:
        animationDelay.value = 0;
        backdropOpacity.value = 1;
        modalOpacity.value = 1;
    }
  }, [animationState, backdropOpacity, modalOpacity, animationDelay]);

  const closeModal = () => {
    // TODO: Do animation for closing
    console.log("Set state to hidden");
    setAnimationState(ModalState.hidden);
    setTimeout(() => {
      console.log("Close Modal");
      setMenuState(MenuState.Menu);
    }, config.duration + 800); // Add Delay of hiding animation
  };

  const maxModalWidth = 2760; // das entscheided über die Größe des Modals in 4K
  const maxModalHeight = (maxModalWidth * 9) / 16;
  let actualModalWidth =
    width / height < 16 / 9
      ? Math.min(width - 48, maxModalWidth)
      : (Math.min(height - 48, maxModalHeight) * 16) / 9;
  return (
    <Animated.View style={StyleSheet.compose(styles.backdrop, backdropStyle)}>
      <ImageBackground
        style={styles.backdrop}
        resizeMode="cover"
        source={require("../assets/images/modal_background.png")}
      >
        <Animated.View
          style={StyleSheet.compose(
            {
              ...styles.modal,
              padding: hasBorder ? 42 : 0,
              width: actualModalWidth,
              height: (actualModalWidth * 9) / 16,
            },
            modalStyle
          )}
        >
          {children}
          <CloseButton
            onPress={closeModal}
            size={width > 2500 ? 56 : 32}
            offset={width > 2500 ? 10 : 10}
          />
        </Animated.View>
      </ImageBackground>
    </Animated.View>
  );
};
// TODO: Fullscreen Modal auf kleinen Geräten, eventuell etwas nach unten Verschoben für Fullscreen
const styles = StyleSheet.create({
  modal: {
    shadowOffset: { width: -10, height: 10 },
    shadowRadius: 25,
    shadowColor: "#000",
    borderRadius: 25,
    overflow: "hidden",
    backgroundColor: "white",
    display: "flex",
  },
  backdrop: {
    backgroundColor: "#fff",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ModalComponent;
