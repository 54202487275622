import { ExpoWebGLRenderingContext } from 'expo-gl';
import { Bezier } from 'bezier-js';
import Config from './Constants';

export const BlobID = {
  Main: 0,

  // left block
  About: 1,
  Video: 2,
  Presentation: 3,
  
  // center block
  Product: 4,
  Marketplace: 5,
  OnBoarding: 6,
  Teaser: 7,
  Journey: 8,
  VirtualWorld: 9,
  Registration: 10,  

  // right block
  GetTouch: 11,
  ContactUs: 12,
  WorkForAs: 13,
}

export const BlobForm = {
  Sphere: 1,
  Cube: 2,
}


const ObjsCount = Object.keys(BlobID).length;

var lastTime = 0;
var idleFactor = 0;

export interface BlobAnimation {
  deltaTime: number; // rest animation time
  time: number; // animation time
  timefunction: string;
  prepared: Boolean;
  blobs: {
    id: number; // index of the blob
    position: Bezier;   // relative position description
    targetX: number;
    targetY: number;
    startSize: number;
    targetSize: number;
    ctrlDir1: any;
    ctrlDir2: any;
  }[];
}

export interface BlobContext {
  isActive: Boolean
  renderTimer: number;
  pixelRatio: number;
  isIdle: Boolean;
  update: Boolean;
  updatePos: Boolean;
  updateSize: Boolean;
  objectCount: number;
  width: number;
  height: number;
  program: WebGLProgram | null;
  gl: ExpoWebGLRenderingContext | null;
  screenViewportData: WebGLBuffer | null;
  
  objChaos: Float32Array;

  objForms: Float32Array;         // 1 == sphere, 2 == cube, ...
  objPositions: Float32Array;     // vec2 position
  objSizes: Float32Array;         // vec2 size

  currObjPositions: Float32Array;     // vec2 position
  currObjSizes: Float32Array;         // vec2 size
  
  // not used currently
  setBlobForm: Function;
  getBlobForm: Function;

  // relative size and position used for scene animations
  setBlobPos: Function;
  setBlobSize: Function;
  getBlobPos: Function;
  getBlobSize: Function;
  
  // absolute size and position used for gl ctx
  setCurrBlobPos: Function;
  setCurrBlobSize: Function;

  updateObjForm: Function;
  updateObjPosition: Function;
  updateObjSize: Function;
  initBuffers: Function;
  
  objProgramResolutionLoc: WebGLUniformLocation;
  objProgramFormLoc: WebGLUniformLocation;
  objProgramPosLoc: WebGLUniformLocation;
  objProgramSizeLoc: WebGLUniformLocation; 
  
  blobAnimations: BlobAnimation[];
  setAnimation: Function;
}


export const ctx: BlobContext = {
  isActive: true,
  renderTimer: 0,
  pixelRatio: 1,
  isIdle: false,
  update: true,
  updatePos: false,
  updateSize: false,
  objectCount: ObjsCount,
  width: 0,
  height: 0,
  gl: null,
  program: null,

  objChaos: new Float32Array(2 * ObjsCount),

  objForms: new Float32Array(ObjsCount),
  objPositions: new Float32Array(2 * ObjsCount),
  objSizes: new Float32Array(2 * ObjsCount),
  currObjPositions: new Float32Array(2 * ObjsCount),
  currObjSizes: new Float32Array(2 * ObjsCount),
  objProgramFormLoc: 0,
  objProgramPosLoc: 0,
  objProgramSizeLoc: 0,
  objProgramResolutionLoc: 0,
  screenViewportData: null,

  getBlobForm: function(index: number): number {
    return this.objForms[index];
  },
  setBlobForm: function(index: number, formID: number) {
    this.objForms[index] = formID;
  },

  getBlobPos: function(index: number): [number, number] {
    let i = index * 2;
    return [this.objPositions[i], this.objPositions[i+1]];
  },
  getBlobSize: function(index: number): [number, number] {
    let i = index * 2;
    return [this.objSizes[i], this.objSizes[i+1]];
  },
  setBlobPos: function(index: number, x: number, y: number) {
    let i = index * 2;
    this.objPositions[i] = x;
    this.objPositions[i+1] = y;
  },
  setBlobSize: function(index: number, x: number, y: number) {
    let i = index * 2;
    this.objSizes[i] = x;
    this.objSizes[i+1] = y;
  },
  setCurrBlobPos: function(index: number, x: number, y: number) {
    let i = index * 2;
    this.currObjPositions[i] = x;
    this.currObjPositions[i+1] = y;
    this.updatePos = true;
  },
  setCurrBlobSize: function(index: number, x: number, y: number) {
    let i = index * 2;
    this.currObjSizes[i] = x;
    this.currObjSizes[i+1] = y;
    this.updateSize = true;
  },
  updateObjForm: function() {
    // this.gl?.uniform1fv(this.objProgramFormLoc, this.objForms);
  },
  updateObjPosition: function() {
    this.gl?.uniform2fv(this.objProgramPosLoc, this.currObjPositions);
  },
  updateObjSize: function() {
    this.gl?.uniform2fv(this.objProgramSizeLoc, this.currObjSizes);
  },
  initBuffers: function() {
    for (var i = 0; i < ObjsCount; i++) {
      let index = i * 2
      ctx.objChaos[index] = Math.random() * 2 * Math.PI;
      ctx.objChaos[index+1] = Math.random() + 0.5;
      ctx.setBlobForm(i, BlobForm.Sphere);
      ctx.setBlobPos(i, 0.9, 0.1);
      ctx.setBlobSize(i, i == 0 ? 1/24 * 0.9 : 0, 0);
    }
    ctx.updateObjForm();
    ctx.updateObjPosition();
    ctx.updateObjSize();
  },

  blobAnimations: [],
  setAnimation: function(anim: BlobAnimation) {
    this.blobAnimations.push(anim);
  }
};

// https://github.com/streamich/ts-easing/blob/master/src/index.ts
function elastic(t: number): number {
  return (t * (33 * t * t * t * t - 106 * t * t * t + 126 * t * t - 67 * t + 15))
}
function inOutCubic(t: number): number {
  return (t <.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
}
function inExpo(t: number): number {
  return (Math.pow(2, 10 * (t - 1)))
}
function inOutQuad(t: number): number {
  // return (t <.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
  return (t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(-2 * t + 2, 4) / 2);
}
function outQuad(t: number): number {
  return (t * (2 - t))
}
function outCubic(t: number): number {
  return ((--t) * t * t + 1)
}
function easeOutQuad(x: number): number {
  return 1 - (1 - x) * (1 - x);
}

const c1 = 1.70158;
const c2 = c1 * 1.525;
const c3 = c1 + 1;
const c4 = (2 * Math.PI) / 3;
function easeOutElastic(x: number): number {
  return x === 0
    ? 0
    : x === 1
    ? 1
    : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}
function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

function easeInOutBack(x: number): number {
  return x < 0.5
    ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
    : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
}

function easeOutBack(x: number): number {
  return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
}

function easeInOutExpo(x: number): number {
  return x === 0
    ? 0
    : x === 1
    ? 1
    : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2
    : (2 - Math.pow(2, -20 * x + 10)) / 2;
}
function easeOutExpo(x: number): number {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}
function easeInOutSine(x: number): number {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

var renderFrames = 0

function redraw(time: number) {
  let delta = Math.min((time - lastTime) / 1000, 0.5);
  lastTime = time;
  ctx.renderTimer = requestAnimationFrame(redraw);

  if(!ctx.gl) return;

  if(ctx.blobAnimations.length > 0) {
    let anim = ctx.blobAnimations[0];
    let isAnimSetPoint = anim.time <= 0.1;
    if(!anim.prepared) {
      anim.prepared = true;
      for(let k=0; k < anim.blobs.length; k++) {
        let blobAnim = anim.blobs[k];
        blobAnim.startSize = ctx.getBlobSize(blobAnim.id)[0];

        let pos = ctx.getBlobPos(blobAnim.id);
        let ctrl1 = blobAnim.ctrlDir1;
        let ctrl2 = blobAnim.ctrlDir2;
        if(ctrl1 && !isAnimSetPoint) {
          blobAnim.position = new Bezier(
            pos[0], pos[1], 
            pos[0] + ctrl1[0], pos[1] + ctrl1[1], 
            blobAnim.targetX + ctrl2[0], blobAnim.targetY + ctrl2[1], 
            blobAnim.targetX, blobAnim.targetY
          )
        } else {
          let centerX = (blobAnim.targetX - pos[0]) * 0.5 + pos[0];
          let centerY = (blobAnim.targetY - pos[1]) * 0.5 + pos[1];
  
          blobAnim.position = new Bezier(
            pos[0], pos[1], 
            centerX, centerY, 
            centerX, centerY, 
            blobAnim.targetX, blobAnim.targetY
          )
        }

      }
    }

    let dt = Math.min(anim.deltaTime / anim.time, 1); // [0,1]
    ctx.update = true;

    // let dtElasticEasing = elastic(dt);
    // let dtEasing = inOutQuad(dt);
    // let dtEasing = easeInOutCubic(dt);

    let dtEasing: number
    switch(anim.timefunction) {
      case "linear":
        dtEasing = dt;
        break
      case "easeout":
        dtEasing = easeOutQuad(dt);
        break
      default:
        dtEasing = easeInOutSine(dt);
    }
    // let dtEasing = easeInOutBack(dt);
    // let dtEasing = inOutCubic(dt);
    // let dtEasing = outQuad(dt);
    // let dtEasing = easeInOutExpo(dt);
    // let dtEasing = easeOutQuad(dt);

    for(let i=0; i<anim.blobs.length; i++) {
      let blobAnim = anim.blobs[i];
   
      // animate position
      let p = blobAnim.position.get(dtEasing);
      ctx.setBlobPos(blobAnim.id, p.x, p.y);

      // animate size
      let s = (blobAnim.startSize + (blobAnim.targetSize - blobAnim.startSize) * dt);
      ctx.setBlobSize(blobAnim.id, s, s);
    }
  
    if(dt >= 1) {
      ctx.blobAnimations.splice(0, 1);
    } else {
      anim.deltaTime += delta;
    }  
  }

  // let sizeFactor = Math.min(ctx.width, ctx.height); 

  if(ctx.isIdle) {
    idleFactor -= delta;
    idleFactor = Math.max(0, idleFactor)
  } else {
    idleFactor += delta;
    idleFactor = Math.min(1, idleFactor)
  }
  
  let sizeFactor = ctx.width;
  for (var i = 0; i < ObjsCount; i++) {
    let s = ctx.getBlobSize(i)[0] * sizeFactor;
    ctx.setCurrBlobSize(i, s, s);
    
    let index = i*2;
    const swingFactor = sizeFactor * 0.00003 * ctx.getBlobSize(i)[0] * idleFactor;
    let deltaX = Math.sin(ctx.objChaos[index]) * swingFactor;
    let deltaY = Math.cos(ctx.objChaos[index] * ctx.objChaos[index+1]) * swingFactor;
    ctx.objChaos[index] += delta * 1;
    let p = ctx.getBlobPos(i);
    ctx.setCurrBlobPos(i, (p[0] + deltaX) * ctx.width, (p[1] + deltaY) * ctx.height);
    // ctx.setCurrBlobPos(i, p[0] * ctx.width, p[1] * ctx.height);
  }
  
  if(!ctx.isActive) 
  return;

  renderFrames++;
  if(renderFrames % 2 != 0) {
    return;
  } 

  if(ctx.updatePos) ctx.updateObjPosition();
  if(ctx.updateSize) ctx.updateObjSize();
  ctx.updatePos = false;
  ctx.updateSize = false;

  ctx.gl.clearColor(1, 1, 1, 1);
  // ctx.gl.clear(ctx.gl.COLOR_BUFFER_BIT);
  
  ctx.gl.viewport(0, 0, ctx.gl.drawingBufferWidth, ctx.gl.drawingBufferHeight);
  ctx.gl.uniform2f(ctx.objProgramResolutionLoc, ctx.width, ctx.height);

  ctx.gl.useProgram(ctx.program);
  ctx.gl.drawArrays(ctx.gl.TRIANGLE_STRIP, 0, 4);

  ctx.gl.flush();
  ctx.gl.endFrameEXP();
}

let isInit = false;

export function onContextCreate(gl: ExpoWebGLRenderingContext | null) {
  if(gl) {
    ctx.gl = gl;
  }

  if(!ctx.gl || ctx.width == 0) {
    return
  }

  console.log("drawingBuffer ", ctx.gl.drawingBufferWidth, ctx.gl.drawingBufferHeight)
  console.log("layout ", ctx.width, ctx.height)
  
  if(isInit) {
    return;
  }

  isInit = true;

  // Create vertex shader (shape & position)
  const vert = ctx.gl.createShader(ctx.gl.VERTEX_SHADER) as WebGLShader;
  ctx.gl.shaderSource(
    vert, `
  precision lowp float;
attribute vec2 position;
varying vec2 point;

void main() {
  point = vec2(position.x, -position.y) / 2.0 + vec2(0.5);
  gl_Position = vec4(position, 0.0, 1.0);
}`
  );
  ctx.gl.compileShader(vert);
  console.log(ctx.gl.getShaderInfoLog(vert) || "compile  VertexShader ...... OK");


  // Create fragment shader (color)
  const frag = ctx.gl.createShader(ctx.gl.FRAGMENT_SHADER) as WebGLShader;
  ctx.gl.shaderSource(
    frag, `
precision lowp float;

uniform vec2 resolution;
uniform vec2 pos[` + ObjsCount + `];
uniform vec2 size[` + ObjsCount + `];

varying vec2 point;

float opSmoothUnion(float d1, float d2, float k){
    float h = max(k-abs(d1-d2),0.0);
    return min(d1, d2) - h*h*0.25/k;
}
float sdSphere(vec3 p, float r){
    return length(p)-r;
}
float map(vec3 p){
  float d = 1e10;
  // Main: 0,

  // About: 1,
  // Video: 2,
  // Presentation: 3,

  // Product: 4,
  // Marketplace: 5,
  // OnBoarding: 6,
  // Teaser: 7,
  // Journey: 8,
  // VirtualWorld: 9,
  // Registration: 10,  
  
  // GetTouch: 11,
  // ContactUs: 12,
  // WorkForAs: 13,

  float d0 = sdSphere(p - vec3(pos[0], 0.0), size[0].x);
  float d1 = sdSphere(p - vec3(pos[1], 0.0), size[1].x);
  float d2 = sdSphere(p - vec3(pos[2], 0.0), size[2].x);
  float d3 = sdSphere(p - vec3(pos[3], 0.0), size[3].x);
  float d4 = sdSphere(p - vec3(pos[4], 0.0), size[4].x);
  float d5 = sdSphere(p - vec3(pos[5], 0.0), size[5].x);
  float d6 = sdSphere(p - vec3(pos[6], 0.0), size[6].x);
  float d7 = sdSphere(p - vec3(pos[7], 0.0), size[7].x);
  float d8 = sdSphere(p - vec3(pos[8], 0.0), size[8].x);
  float d9 = sdSphere(p - vec3(pos[9], 0.0), size[9].x);
  float d10 = sdSphere(p - vec3(pos[10], 0.0), size[10].x);
  float d11 = sdSphere(p - vec3(pos[11], 0.0), size[11].x);
  float d12 = sdSphere(p - vec3(pos[12], 0.0), size[12].x);
  float d13 = sdSphere(p - vec3(pos[13], 0.0), size[13].x);
  
  // central animation
  float dc0 = opSmoothUnion(d0,d1, min(size[0].x,size[1].x));
  float dc1 = opSmoothUnion(d0,d4, min(size[0].x,size[4].x));
  float dc2 = opSmoothUnion(d0,d11, min(size[0].x,size[11].x));
  
  d = min(d, dc0);
  d = min(d, dc1);
  d = min(d, dc2);

  d = min(d, d0);
  d = min(d, d1);
  d = min(d, d2);
  d = min(d, d3);
  d = min(d, d4);
  d = min(d, d5);
  d = min(d, d6);
  d = min(d, d7);
  d = min(d, d8);
  d = min(d, d9);
  d = min(d, d10);
  d = min(d, d11);
  d = min(d, d12);
  d = min(d, d13);
  

  // for(int i=0;i<` + ObjsCount + `;i++) {
  //   float d1 = sdSphere(p - vec3(pos[i], 0.0), size[i].x);
  //   for(int j=0;j<` + ObjsCount + `;j++) {
  //     if(i!=j){
  //       float d2 = sdSphere(p - vec3(pos[j],0.0), size[j].x);
  //       float dt = opSmoothUnion(d1,d2, min(size[j].x,size[i].x));
  //       d = min(d, dt);
  //     }
  //   }
  // }
  return d;
}

const float aa = 1.4;
const float aaFactor = 1.0 / aa;
const vec4 magenta = vec4(211.0/255.0, 17.0/255.0, 132.0/255.0, 1.0);
const vec4 lightOrange = vec4(253.0/255.0, 185.0/255.0, 19.0/255.0, 1.0);

void main(){
  float d = 1e10;
  vec3 p = vec3(point * resolution,0.0);
  d = map(p);
  if(d<aa){
    vec4 gradient = mix(lightOrange, magenta, mix(-0.5,1.5,point.x));
    float antializing = mix(1.0, 0.0, max(d, 0.0) * aaFactor);
    gl_FragColor = gradient * antializing;
  } else {
    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
  }

}`
  );
  ctx.gl.compileShader(frag);
  console.log(ctx.gl.getShaderInfoLog(frag) || "compile FragmentShader ..... OK");

  // Link together into a program
  const program: WebGLProgram = ctx.gl.createProgram() as WebGLProgram;
  ctx.gl.attachShader(program, vert);
  ctx.gl.attachShader(program, frag);
  ctx.gl.linkProgram(program);
  console.log(ctx.gl.getProgramInfoLog(program) || "link WebGLProgram .......... OK");

  ctx.gl.useProgram(program);
  
  ctx.screenViewportData = ctx.gl.createBuffer();
  var vertexData = new Float32Array([-1, 1, -1,-1, 1, 1, 1,-1]);
  ctx.gl.bindBuffer(ctx.gl.ARRAY_BUFFER, ctx.screenViewportData);
  ctx.gl.bufferData(ctx.gl.ARRAY_BUFFER, vertexData, ctx.gl.STATIC_DRAW);

  var positionHandle = ctx.gl.getAttribLocation(program, 'position');
  ctx.gl.enableVertexAttribArray(positionHandle);
  ctx.gl.vertexAttribPointer(positionHandle, 2, ctx.gl.FLOAT, false, 2 * 4, 0);

  ctx.objProgramResolutionLoc = ctx.gl.getUniformLocation(program, 'resolution') as WebGLUniformLocation;
  // ctx.objProgramFormLoc = ctx.gl.getUniformLocation(program, 'form') as WebGLUniformLocation;
  ctx.objProgramPosLoc = ctx.gl.getUniformLocation(program, 'pos') as WebGLUniformLocation;
  ctx.objProgramSizeLoc = ctx.gl.getUniformLocation(program, 'size') as WebGLUniformLocation;

  
  ctx.program = program;
  ctx.initBuffers();
  
  cancelAnimationFrame(ctx.renderTimer);
  ctx.renderTimer = requestAnimationFrame(redraw);
}