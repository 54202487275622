import React from "react";
import { StyleSheet } from "react-native";
import { IFrameComponentProps } from "./IFrameComponent";
const IFrameComponent = ({ src }: IFrameComponentProps) => {
  return <iframe style={styles.iframe} src={src} />;
};

const styles = StyleSheet.create({
  iframe: {
    width: "100%",
    height: "100%",
    borderWidth: 0,
    borderColor: "transparent",
  },
});

export default IFrameComponent;
