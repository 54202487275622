import React, { useEffect, useRef, useState } from 'react';
import { LayoutChangeEvent, Pressable, View, Text, StyleSheet, Animated } from 'react-native';
import Constants, { MenuState, BlobScene } from './Constants';
import BlobConfig from './BlobSceneSettings';
import { ctx } from './BlobContext'
import { animateToScene } from './BlobAnimator';
import WorkForUsQrCode from './WorkForUsQrCode';

let currentBlobScene = BlobScene.Startup;

export default function Menu({width, height, font, menuState, setMenuState}: 
    {width: number, height: number, font: string, menuState: MenuState, setMenuState: Function} 
) {
  const [aboutIsOpen, setAboutIsOpen] = useState(false);

  const [productServiceOpen, setProductServiceOpen] = useState(false);
  const [onBoardingOpen, setOnBoardingOpen] = useState(false);

  const [inTouchOpen, setInTouchOpen] = useState(false);

  const [multiCloseProduct, setMultiCloseProduct] = useState(false);
  const [showScanMe, setShowScanMe] = useState(false);
  const prevMenuState = useRef(-1);
  


  const opacityTouchMeValue = useRef(new Animated.Value(0)).current; 
  const opacitySubMenuValue = useRef(new Animated.Value(menuState <= MenuState.Menu ? 0 : 1)).current; 

  const opacityVideo = useRef(new Animated.Value(0)).current;
  const opacityPresentation = useRef(new Animated.Value(0)).current;

  const opacityMarketPlace = useRef(new Animated.Value(0)).current;
  const opacityOnBoarding = useRef(new Animated.Value(0)).current;
  const opacityTeaser = useRef(new Animated.Value(0)).current;
  const opacityJourney = useRef(new Animated.Value(0)).current;
  const opacityVirtualWorld = useRef(new Animated.Value(0)).current;
  const opacityRegistration = useRef(new Animated.Value(0)).current;

  const opacityContactUs = useRef(new Animated.Value(0)).current;
  const opacityWorkForUs = useRef(new Animated.Value(0)).current;

  function closeMenu() {
    animateToScene(BlobConfig.buildCloseAnimation(
      aboutIsOpen,
      productServiceOpen, onBoardingOpen,
      inTouchOpen, 
      false
    ));

    setAboutIsOpen(false);
    setProductServiceOpen(false);
    if(productServiceOpen) {
      setOnBoardingOpen(false);
      setMultiCloseProduct(true);
    }

    setInTouchOpen(false);
  }

  function toggleAboutConfinity() {
    if(aboutIsOpen) {
      animateToScene(BlobConfig.buildCloseAnimation(
        aboutIsOpen,
        false, false,
        false, 
        false
      ));
      setAboutIsOpen(false);
    } else {
      setAboutIsOpen(true);
      animateToScene(BlobConfig.getAnimation(BlobScene.AboutConfinityX));
    }
  }
  
  function toggleProductServiceOpen() {
    if(productServiceOpen) {
      animateToScene(BlobConfig.buildCloseAnimation(
        false,
        productServiceOpen, onBoardingOpen,
        false, 
        false
      ));
      if(onBoardingOpen) {
        setOnBoardingOpen(false);
        setMultiCloseProduct(true);
      }
      setProductServiceOpen(false);
    } else {
      setMultiCloseProduct(false);
      setProductServiceOpen(true);
      animateToScene(BlobConfig.getAnimation(BlobScene.ProductAndService));
    }
  }
  function toggleInTouchOpen() {
    if(inTouchOpen) {
      setInTouchOpen(false);
      animateToScene(BlobConfig.getAnimation(BlobScene.GetInTouch, true));
    } else {
      setInTouchOpen(true);
      animateToScene(BlobConfig.getAnimation(BlobScene.GetInTouch));
    }
  }
  function toggleOnBoardingOpen() {
    if(onBoardingOpen) {
      setOnBoardingOpen(false);
      animateToScene(BlobConfig.getAnimation(BlobScene.OnBoarding, true));
    } else {
      setOnBoardingOpen(true);
      animateToScene(BlobConfig.getAnimation(BlobScene.OnBoarding));
    }
  }


  if(currentBlobScene == BlobScene.Startup) {
    // on start app
    if(width != 0) {
      currentBlobScene = BlobScene.Idle;
      animateToScene(BlobConfig.getAnimation(BlobScene.Menu));

    }
  }


  useEffect(() => {
    // console.log(prevMenuState.current, " ", menuState)
    if(prevMenuState.current != menuState) {
      if(menuState == MenuState.Menu){
        if(prevMenuState.current == MenuState.Idle) {
          animateToScene(BlobConfig.getAnimation(BlobScene.Menu));
        } 
      } else if (prevMenuState.current == -1) {
        animateToScene(BlobConfig.getAnimation(BlobScene.Menu));
      } else if (menuState == MenuState.Idle) {
        animateToScene(BlobConfig.buildCloseAnimation(
          aboutIsOpen,
          productServiceOpen, onBoardingOpen,
          inTouchOpen, 
          true
        ));
    
        setAboutIsOpen(false);
        setProductServiceOpen(false);
        if(productServiceOpen) {
          setOnBoardingOpen(false);
          setMultiCloseProduct(true);
        }
    
        setInTouchOpen(false);
      }
    }

    Animated.timing(opacityTouchMeValue, {
      toValue: menuState == MenuState.Idle ? 1 : 0,
      duration: menuState > MenuState.Idle ? 100 : 100,
      delay: menuState == MenuState.Idle ? (prevMenuState.current > MenuState.Idle ? 4000 : 1000) : 100,
      useNativeDriver: true,
    }).start();
    Animated.timing(opacitySubMenuValue, {
      toValue: menuState >= MenuState.Menu ? 1 : 0,
      duration: opaDurFactor,
      delay: 100,
      useNativeDriver: true,
    }).start();

    prevMenuState.current = menuState;

  }, [menuState]);
  
  const opaDurFactor = 500;
  const opaDelayFactor1 = 500;
  const opaDelayFactor2 = 1500;
  const opaDelayFactor3 = 2500;
  const opaDelayFactor4 = 3500;

  const opaDelayMultiFactor1 = 0.6 * BlobConfig.subBlobSplitTime * 1000;
  const opaDelayMultiFactor2 = 1.6 * BlobConfig.subBlobSplitTime * 1000;
  const opaDelayMultiFactor3 = 2.6 * BlobConfig.subBlobSplitTime * 1000;
  const opaDelayMultiFactor4 = 3.6 * BlobConfig.subBlobSplitTime * 1000;
  const opaDelayMultiFactor5 = 4.6 * BlobConfig.subBlobSplitTime * 1000;
  const opaDelayMultiFactor6 = 5.6 * BlobConfig.subBlobSplitTime * 1000;

  useEffect(() => {
    Animated.timing(opacityVideo, {
      toValue: aboutIsOpen ? 1 : 0,
      duration: BlobConfig.subBlobSplitTime * opaDurFactor,
      delay: BlobConfig.subBlobSplitTime * (aboutIsOpen ?  opaDelayFactor1 : opaDelayFactor2),
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityPresentation, {
      toValue: aboutIsOpen ? 1 : 0,
      duration: BlobConfig.subBlobSplitTime * opaDurFactor,
      delay: BlobConfig.subBlobSplitTime * (aboutIsOpen ?  opaDelayFactor2 : opaDelayFactor1),
      useNativeDriver: true,
    }).start();
  }, [aboutIsOpen]);


  useEffect(() => {
    Animated.timing(opacityMarketPlace, {
      toValue: productServiceOpen ? 1 : 0,
      duration: BlobConfig.subBlobSplitTime * opaDurFactor,
      delay: multiCloseProduct ? opaDelayMultiFactor6 : (BlobConfig.subBlobSplitTime * (productServiceOpen ?  opaDelayFactor1 : opaDelayFactor2)),
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityOnBoarding, {
      toValue: productServiceOpen ? 1 : 0,
      duration: BlobConfig.subBlobSplitTime * opaDurFactor,
      delay: multiCloseProduct ? opaDelayMultiFactor5 : (BlobConfig.subBlobSplitTime * (productServiceOpen ?  opaDelayFactor2 : opaDelayFactor1)),
      useNativeDriver: true,
    }).start();
  }, [productServiceOpen]);

  useEffect(() => {
    Animated.timing(opacityTeaser, {
      toValue: onBoardingOpen ? 1 : 0,
      duration: BlobConfig.subSubBlobSplitTime * opaDurFactor,
      delay: multiCloseProduct ? opaDelayMultiFactor4 : (BlobConfig.subSubBlobSplitTime * (onBoardingOpen ?  opaDelayFactor1 : opaDelayFactor4)),
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityJourney, {
      toValue: onBoardingOpen ? 1 : 0,
      duration: BlobConfig.subSubBlobSplitTime * opaDurFactor,
      delay: multiCloseProduct ? opaDelayMultiFactor3 : (BlobConfig.subSubBlobSplitTime * (onBoardingOpen ?  opaDelayFactor2 : opaDelayFactor3)),
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityVirtualWorld, {
      toValue: onBoardingOpen ? 1 : 0,
      duration: BlobConfig.subSubBlobSplitTime * opaDurFactor,
      delay: multiCloseProduct ? opaDelayMultiFactor2 : (BlobConfig.subSubBlobSplitTime * (onBoardingOpen ?  opaDelayFactor3 : opaDelayFactor2)),
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityRegistration, {
      toValue: onBoardingOpen ? 1 : 0,
      duration: BlobConfig.subSubBlobSplitTime * opaDurFactor,
      delay: multiCloseProduct ? opaDelayMultiFactor1 : (BlobConfig.subSubBlobSplitTime * (onBoardingOpen ?  opaDelayFactor4 : opaDelayFactor1)),
      useNativeDriver: true,
    }).start();
  }, [onBoardingOpen]);

  useEffect(() => {
    Animated.timing(opacityContactUs, {
      toValue: inTouchOpen ? 1 : 0,
      duration: BlobConfig.subBlobSplitTime * opaDurFactor,
      delay: BlobConfig.subBlobSplitTime * (inTouchOpen ?  opaDelayFactor1 : opaDelayFactor2),
      useNativeDriver: true,
    }).start();
    Animated.timing(opacityWorkForUs, {
      toValue: inTouchOpen ? 1 : 0,
      duration: BlobConfig.subBlobSplitTime * opaDurFactor,
      delay: BlobConfig.subBlobSplitTime * (inTouchOpen ?  opaDelayFactor2 : opaDelayFactor1),
      useNativeDriver: true,
    }).start();
  }, [inTouchOpen]);


  const subHeight = (BlobConfig.subBlobSize * 2) * height;
  const sub1Y = BlobConfig.sub1BlobY * height - subHeight * 0.5;
  const sub2Y = BlobConfig.sub2BlobY * height - subHeight * 0.5;

  const subSub11Y = BlobConfig.subSub11BlobY * height - subHeight * 0.5;
  const subSub12Y = BlobConfig.subSub12BlobY * height - subHeight * 0.5;
  const subSub21Y = BlobConfig.subSub21BlobY * height - subHeight * 0.5;
  const subSub22Y = BlobConfig.subSub22BlobY * height - subHeight * 0.5;
  

  const subSubSub11Y = BlobConfig.subSubSub11BlobY * height - subHeight * 0.5;
  const subSubSub12Y = BlobConfig.subSubSub12BlobY * height - subHeight * 0.5;
  const subSubSub21Y = BlobConfig.subSubSub21BlobY * height - subHeight * 0.5;
  const subSubSub22Y = BlobConfig.subSubSub22BlobY * height - subHeight * 0.5;
  const subSubSub23Y = BlobConfig.subSubSub23BlobY * height - subHeight * 0.5;
  const subSubSub24Y = BlobConfig.subSubSub24BlobY * height - subHeight * 0.5;

  
  const leftTextX = (BlobConfig.blobCenterX - BlobConfig.blobCenterOffsetX + BlobConfig.subBlobSize * 1.3) * width;
  const centerTextX = (BlobConfig.blobCenterX + BlobConfig.subBlobSize * 1.3) * width;
  const rightTextX = (BlobConfig.blobCenterX + BlobConfig.blobCenterOffsetX + BlobConfig.subBlobSize * 1.3) * width;


  const generalSubTextStyles = {
    height: subHeight,
    width: 0.2 * width,
    opacity: opacitySubMenuValue,
    fontFamily: font,
    fontSize: Constants.fontSize * width,
    color: Constants.colors.steelGray,
  }
  const generalSubSubTextStyles = {
    height: subHeight,
    width: 0.2 * width,
    fontFamily: font,
    fontSize: Constants.fontSize * width * 0.8,
    color: Constants.colors.steelGray,
  }
  const generalSubSubSubTextStyles = {
    height: subHeight,
    width: 0.2 * width,
    fontFamily: font,
    fontSize: Constants.fontSize * width * 0.6,
    color: Constants.colors.steelGray,
  }


  let pressCenter = 2.2 * BlobConfig.mainBlobSize * width;
  let pressSubSize = 2.6 * BlobConfig.subBlobSize * width;
  let pressSubSubSize = 2.6 * BlobConfig.subSubBlobSize * width;
  let pressSubSubSubSize = 2.2 * BlobConfig.subSubSubBlobSize * width;

  let pressAboutWidth = 3.7 * BlobConfig.subBlobSize * width;
  let pressMarketPlaceWidth = 3 * BlobConfig.subBlobSize * width;
  let pressStayWidth = pressMarketPlaceWidth;

  let idleTextWidth = BlobConfig.idleMainBlobSize * 2 * width;
  let idleTextHeight = BlobConfig.idleMainBlobSize * 2 * height;

  function writeAnim(text: string, animVal: number) {
    let len = text.length - 1;
    let res = text.substring(0, Math.round(animVal * len));
    console.log(res)
    return res;
  }
   
  return (
    <View 
      style={{position: 'absolute', top: 0, left: 0}}
    >
      <Animated.Text selectable={false} style={{
        position: 'absolute', 
        textAlign: "center",
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        width: idleTextWidth,
        height: idleTextHeight,
        opacity: opacityTouchMeValue,
        fontFamily: font,
        fontSize: Constants.fontSize * width,
        color: Constants.colors.lightOrange,
        left: BlobConfig.idleX * width - idleTextWidth * 0.5, 
        top: BlobConfig.idleY * height - idleTextHeight * 0.5,
      }} >
        Menu
      </Animated.Text>
      <Animated.Text selectable={false} style={{
        ...generalSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        left: leftTextX, 
        top: sub1Y,
        color: "#fccc0a",
      }} >
        About Cofinity-X
        {/* { writeAnim("About Cofinity-X", opacitySubMenuValue) } */}
      </Animated.Text>
      <Animated.Text selectable={false} style={{
        ...generalSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        left: centerTextX, 
        top: sub2Y,
        color: "#ea6e45"
      }}>
        Products & Services
      </Animated.Text>
      <Animated.Text selectable={false} style={{
        ...generalSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        left: rightTextX, 
        top: sub1Y,
        color: "#d71f7a"
      }}>
        Get in touch
      </Animated.Text>

      <Animated.Text selectable={false}  style={{
        ...generalSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute', 
        opacity: opacityVideo,
        left: leftTextX, 
        top: subSub11Y ,
      }}>
        Video
      </Animated.Text>
      <Animated.Text selectable={false}  style={{
        ...generalSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute', 
        opacity: opacityPresentation,
        left: leftTextX, 
        top: subSub12Y,
      }}>
        Presentation
      </Animated.Text>

      <Animated.Text selectable={false}  style={{
        ...generalSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityMarketPlace,
        left: centerTextX, 
        top: subSub21Y,
      }}>
        Marketplace
      </Animated.Text>
      <Animated.Text selectable={false}  style={{
        ...generalSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityOnBoarding,
        left: centerTextX, 
        top: subSub22Y,
      }}>
        Onboarding
      </Animated.Text>
      <Animated.Text selectable={false}  style={{
        ...generalSubSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityTeaser,
        left: centerTextX, 
        top: subSubSub21Y,
      }}>
        Teaser
      </Animated.Text>
      <Animated.Text selectable={false}  style={{
        ...generalSubSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityJourney,
        left: centerTextX, 
        top: subSubSub22Y,
      }}>
        Journey
      </Animated.Text>
      <Animated.Text selectable={false}  style={{
        ...generalSubSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityVirtualWorld,
        left: centerTextX, 
        top: subSubSub23Y,
      }}>
        Virtual World (Preview)
      </Animated.Text>
      <Animated.Text selectable={false}  style={{
        ...generalSubSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityRegistration,
        left: centerTextX, 
        top: subSubSub24Y,
      }}>
        Registration (Demo)
      </Animated.Text>


      <Animated.Text selectable={false}  style={{
        ...generalSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityContactUs,
        left: rightTextX, 
        top: subSub11Y,
      }}>
        Contact us
      </Animated.Text>
      <Animated.Text selectable={false} style={{
        ...generalSubSubTextStyles,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        position: 'absolute',
        opacity: opacityWorkForUs,
        left: rightTextX, 
        top: subSub12Y,
      }}>
        Work for us
      </Animated.Text>
      <WorkForUsQrCode
        left={rightTextX}
        top={subSub12Y}
        width={generalSubSubTextStyles.width}
        menuWidth={width}
        visible={inTouchOpen}
        visibleTimingDuration={BlobConfig.subBlobSplitTime * opaDurFactor}
        visibleDelay={BlobConfig.subBlobSplitTime * (inTouchOpen ? opaDelayFactor2 : opaDelayFactor1)}
        showScanMeToggle={showScanMe}
      />
      { menuState == MenuState.Menu ? 
        <View style={{position: 'absolute', top: 0, left: 0, height: "100%"}}>
          <Pressable onPress={closeMenu} 
            disabled={menuState != MenuState.Menu}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressCenter * 0.5, 
              top: BlobConfig.mainBlobY * height - pressCenter * 0.5,
              height: pressCenter,
              width: pressCenter,
            }}
          />
          <Pressable onPress={toggleAboutConfinity} 
            disabled={menuState != MenuState.Menu}
            style={{
              position: 'absolute',
              left: (BlobConfig.blobCenterX - BlobConfig.blobCenterOffsetX) * width - pressSubSize * 0.5, 
              top: BlobConfig.sub1BlobY * height - pressSubSize * 0.5,
              height: pressSubSize,
              width: pressSubSize * 3,
            }}
          />
          <Pressable onPress={toggleProductServiceOpen}
            disabled={menuState != MenuState.Menu}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressSubSize * 0.5, 
              top: BlobConfig.sub2BlobY * height - pressSubSize * 0.5,
              height: pressSubSize,
              width: pressSubSize * 3,
            }}
          />
          <Pressable onPress={toggleInTouchOpen}
            disabled={menuState != MenuState.Menu}
            style={{
              position: 'absolute',
              left: (BlobConfig.blobCenterX + BlobConfig.blobCenterOffsetX) * width - pressSubSize * 0.5,
              top: BlobConfig.sub1BlobY * height - pressSubSize * 0.5,
              height: pressSubSize,
              width: pressSubSize * 3,
            }}
          />

          {/* ~~~~~~~~~~~~~~~ left pressables ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
          <Pressable onPress={() => setMenuState(MenuState.Video)} 
            disabled={!aboutIsOpen}
            style={{
              position: 'absolute',
              left: (BlobConfig.blobCenterX - BlobConfig.blobCenterOffsetX) * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSub11BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
          <Pressable onPress={() => setMenuState(MenuState.Presentation)} 
            disabled={!aboutIsOpen}
            style={{
              position: 'absolute',
              left: (BlobConfig.blobCenterX - BlobConfig.blobCenterOffsetX) * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSub12BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />

          {/* ~~~~~~~~~~~~~~~ center pressables ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
          <Pressable onPress={() => setMenuState(MenuState.MarketPlace)} 
            disabled={!productServiceOpen}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSub21BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
          <Pressable onPress={toggleOnBoardingOpen} 
            disabled={!productServiceOpen}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSub22BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
          <Pressable onPress={() => setMenuState(MenuState.Teaser)} 
            disabled={!onBoardingOpen}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSubSub21BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
          <Pressable onPress={() => setMenuState(MenuState.Journey)} 
            disabled={!onBoardingOpen}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSubSub22BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
          <Pressable onPress={() => setMenuState(MenuState.VirualWorld)} 
            disabled={!onBoardingOpen}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSubSub23BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
          <Pressable onPress={() => setMenuState(MenuState.Registration)} 
            disabled={!onBoardingOpen}
            style={{
              position: 'absolute',
              left: BlobConfig.blobCenterX * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSubSub24BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />

           {/* ~~~~~~~~~~~~~~~ right pressables ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
           <Pressable onPress={() => setMenuState(MenuState.ContactUs)} 
            disabled={!inTouchOpen}
            style={{
              position: 'absolute',
              left: (BlobConfig.blobCenterX + BlobConfig.blobCenterOffsetX) * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSub11BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
          <Pressable onPress={() => { setShowScanMe(!showScanMe) }} 
            disabled={!inTouchOpen}
            style={{
              position: 'absolute',
              left: (BlobConfig.blobCenterX + BlobConfig.blobCenterOffsetX) * width - pressSubSubSize * 0.5, 
              top: BlobConfig.subSub12BlobY * height - pressSubSubSize * 0.5,
              width: pressSubSubSize * 6,
              height: pressSubSubSize,
            }}
          />
        </View>
        : null
      }
    </View>
  );
}

