import React from 'react';
import { LayoutChangeEvent, PixelRatio } from 'react-native';
import { ExpoWebGLRenderingContext, GLView } from 'expo-gl';
import { ctx, onContextCreate } from './BlobContext'
import { runOnUI } from 'react-native-reanimated';

export default function GLBlobView() {
  return (
    <GLView 
      style={{ position: "absolute", width: "100%", height: "100%", overflow: "hidden", }}
      onContextCreate={onContextCreate}
      msaaSamples={0}
      
      onLayout={(ev: LayoutChangeEvent) => {       
        ctx.update = true;      
        ctx.pixelRatio = PixelRatio.getPixelSizeForLayoutSize(ev.nativeEvent.layout.width) / ev.nativeEvent.layout.width;
        ctx.width = ev.nativeEvent.layout.width; 
        ctx.height = ev.nativeEvent.layout.height;
        onContextCreate(null);
        // ctx.width = ctx.gl.drawingBufferWidth / (window.devicePixelRatio || 1); 
        // ctx.height = ctx.gl.drawingBufferHeight / (window.devicePixelRatio || 1);
        
      }}
    />
  );
}

