import React, { useEffect, useState } from "react";
import { AVPlaybackSource, ResizeMode, Video } from "expo-av";
import { StyleSheet } from "react-native";
import { useAssets } from "expo-asset";
interface VideoComponentProps {
  assetId: number;
  useNativeControls: boolean;
  isMuted: boolean;
  isLooping?: boolean;
}
export const VideoComponent = (props: VideoComponentProps) => {
  const {
    assetId = 0,
    useNativeControls = true,
    isMuted = false,
    isLooping = false,
  } = props;
  const [asset, error] = useAssets([
    //TODO: Include right video
    require("./../assets/videos/About_HD_small.mp4"),
    require("./../assets/videos/Onboarding_Teaser_HD_small.mp4"),
    require("./../assets/videos/Registration_small.mp4"),
    require("./../assets/videos/Virtual_World_small.mp4"),

    // "https://www.cofinity-x.com/wp-content/uploads/2023/01/OpenSource-Cofinity-X-Imagevideo_DE.mp4",
  ]);
  const [source, setSource] = useState<AVPlaybackSource | undefined>(undefined);
  useEffect(() => {
    console.log(error, asset);
    if (asset) {
      setSource(asset[assetId]);
    }
  }, [asset, error, assetId]);

  const video = React.useRef(null);
  // if (props.uri) {
  //   setSource({ uri: props.uri });
  // }
  return (
    <Video
      ref={video}
      source={source}
      resizeMode={ResizeMode.CONTAIN}
      style={styles.wrapper}
      useNativeControls={useNativeControls}
      isMuted={isMuted}
      shouldPlay={true}
      videoStyle={styles.video}
      isLooping={isLooping}
    />
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    height: "100%",
  },
  video: {
    width: "100%",
    height: "100%",
  },
});
