import { Pressable, StyleSheet } from "react-native";
import React from "react";
import { Image } from "expo-image";
export enum GalleryArrowDirection {
  Next = 1,
  Previous = -1,
}
const GalleryArrowImage = {
  [GalleryArrowDirection.Next]: require("../assets/images/arrow_right.svg"),
  [GalleryArrowDirection.Previous]: require("../assets/images/arrow_left.svg"),
};
interface GalleryArrowProps {
  direction: GalleryArrowDirection;
  changeIndex: any;
  size: number;
  disabled: boolean;
}
const GalleryArrow = (props: GalleryArrowProps) => {
  const arrowImage = GalleryArrowImage[props.direction];
  return (
    <Pressable style={StyleSheet.compose(styles.button, { opacity: props.disabled ? 0 : 1 })} onPress={() => props.changeIndex(props.direction)} disabled={props.disabled}>
      <Image style={{
        width: props.size,
        height: props.size
      }} source={arrowImage} />
    </Pressable>
  );
};

export default GalleryArrow;

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    display: 'flex',
  }
});
