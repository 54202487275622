import { Pressable, StyleSheet } from "react-native";
import { Image } from "expo-image";
import React from "react";
import { Asset } from "expo-asset";

const CloseButton = (props: any) => {
  return (
    <Pressable style={StyleSheet.compose(styles.button, { 
        width: props.size, 
        height: props.size,
        right: props.offset,
        top: props.offset 
    })} onPress={props.onPress}>
      <Image
        style={styles.image}
        source={require("../assets/images/icon_cross.svg")}
      />
    </Pressable>
  );
};

export default CloseButton;

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: "100%",
    width: "100%",
  },
});
