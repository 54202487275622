

export enum MenuState {
  // Menü Zustände
  Idle,
  Menu,
  
  // WhoWeAre,
  Video,             
  Presentation,

  MarketPlace,
  Teaser,
  Journey,
  VirualWorld,
  Registration,

  ContactUs,
  WorkForUs,
}

export const BlobScene = {
  // Animations Zustände
  Startup: 0,
  Idle: 1,
  Menu: 2,

  AboutConfinityX: 3, // sub menu animationen
  ProductAndService: 4,
  GetInTouch: 5,

  OnBoarding: 7,

  // rückwärts animationen
  CloseSubSubMenu: 10,
  CloseSubMenu: 11,
  MenuToIdle: 12,
}


export default {
  colors: {
    magenta: "#d31184",
    lightOrange: "#fdb913",
    steelGray: "#4d4d4d",
    orange: "#f58220",
    plum: "#5a1162",
    purple: "#524fa1",
    blue: "#2484c6",
    darkGreen: "#6ba13c",
    lightGreen: "#a6ce39",
  },
  fontSize: 0.0172,
  smoothness: 50.25,
}

export const aboutPresentation = [
  require("../assets/images/about/Slide1.jpeg"),
  require("../assets/images/about/Slide2.jpeg"),
  require("../assets/images/about/Slide3.jpeg"),
  require("../assets/images/about/Slide4.jpeg"),
  require("../assets/images/about/Slide5.jpeg"),
  require("../assets/images/about/Slide6.jpeg"),
  require("../assets/images/about/Slide7.jpeg"),
  require("../assets/images/about/Slide8.jpeg"),
  require("../assets/images/about/Slide9.jpeg"),
  require("../assets/images/about/Slide10.jpeg"),
  require("../assets/images/about/Slide11.jpeg"),
  require("../assets/images/about/Slide12.jpeg"),
  require("../assets/images/about/Slide13.jpeg"),
  require("../assets/images/about/Slide14.jpeg"),
  require("../assets/images/about/Slide15.jpeg"),
  require("../assets/images/about/Slide16.jpeg"),
  require("../assets/images/about/Slide17.jpeg"),
  require("../assets/images/about/Slide18.jpeg"),
  require("../assets/images/about/Slide19.jpeg"),
  require("../assets/images/about/Slide20.jpeg"),
  require("../assets/images/about/Slide21.jpeg"),
  require("../assets/images/about/Slide22.jpeg"),
];

export const onboardingJourney = [
  require("../assets/images/journey/Slide1.jpeg"),
  require("../assets/images/journey/Slide2.jpeg"),
  require("../assets/images/journey/Slide3.jpeg"),
  require("../assets/images/journey/Slide4.jpeg"),
  require("../assets/images/journey/Slide5.jpeg"),
  require("../assets/images/journey/Slide6.jpeg"),
  require("../assets/images/journey/Slide7.jpeg"),
  require("../assets/images/journey/Slide8.jpeg"),
]
export const images = [
  require("../assets/images/overlay.svg"),
  require("../assets/images/icon_cross.svg"),
  require("../assets/images/arrow_left.svg"),
  require("../assets/images/arrow_right.svg"),
  require("../assets/images/modal_background.svg"),
  require("../assets/images/Technical_Explanation_Draft-1.png"),
  require("../assets/images/Technical_Explanation_Draft_v2.0-1.png"),
  require("../assets/images/Technical_Explanation_Draft_v2.0-2.png"),
  require("../assets/images/work_for_us_qr.png"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_01.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_02.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_03.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_04.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_05.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_06.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_07.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_08.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_09.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_10.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_11.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_12.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_13.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_14.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_15.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_16.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_17.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_18.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_19.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_20.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_21.jpg"),
  ...aboutPresentation,
  ...onboardingJourney
]

export const presentationImages = [
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_01.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_02.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_03.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_04.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_05.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_06.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_07.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_08.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_09.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_10.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_11.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_12.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_13.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_14.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_15.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_16.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_17.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_18.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_19.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_20.jpg"),
  require("../assets/images/presentation/230404__Cofinity-X_Introduction_Page_21.jpg"),
]

// export const videos = [
//   require("../assets/videos/OpenSource-Cofinity-X-Imagevideo_DE.mp4"),
// ]

export const fonts = [{
  Karbon: require("../assets/fonts/Karbon-Regular.otf"),
  KarbonMedium: require("../assets/fonts/Karbon-Medium.otf"),
  KarbonBold: require("../assets/fonts/Karbon-Bold.otf"),
},
];
